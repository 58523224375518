


























import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import JobRoleTable from "@/components/job-role/JobRoleTable.vue";
import JobRoleCreateDialog from "@/components/dialogs/JobRoleCreateDialog.vue";
import JobRoleUpdateDialog from "@/components/dialogs/JobRoleUpdateDialog.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { JobRoleModel } from "@/api/generated";
import Discriminator from '../../types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: { JobRoleCreateDialog, JobRoleUpdateDialog, JobRoleTable }
})
export default class JobRoleOverview extends Vue {
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;
  @Prop({default: "Job Roles"}) TableTitle!: string;

  private tableRefreshKey = false;
  private createDialog = false;
  private updateDialog = false;
  private updateJobRoleId = "";

  private onCreateJobRole() {
    this.createDialog = true;
  }

  private onUpdateJobRole(jobRole: JobRoleModel) {
    if (!jobRole.jobRoleId) {
      return;
    }

    this.updateJobRoleId = jobRole.jobRoleId;
    this.updateDialog = true;
  }

  private async onDeleteJobRole(jobRole: JobRoleModel) {
    if (!jobRole.jobRoleId) {
      return;
    }

    try {
      await Api.JobRoleService.apiJobroleJobRoleIdDelete(jobRole.jobRoleId);
      snackbarModule.setSnackbarMessage(`Job role successfully deleted`);
    } catch {
      snackbarModule.setSnackbarMessage(`Failed to delete job role`);
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
}
