




import JobRoleOverview from "../../components/job-role/JobRoleOverview.vue"; 
import Vue from "vue";
import Discriminator from '../../types/Discriminator';

export default Vue.extend({
  components: {
    JobRoleOverview
  },
  data() {
    return {
      discriminator: Discriminator.LAND_WORKERS
    }
  }
})
