































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Validation from "@/mixins/validation";
import Api from "@/api";
import { JobRoleUpsertModel } from "@/api/generated";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class JobRoleUpdateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;
  
  @Prop(String) jobRoleId!: string;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: JobRoleUpsertModel = {
    name: "",
    discriminator: getContractType(this.discriminator)
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      this.form?.reset();
    }

    const response = await Api.JobRoleService.apiJobroleJobRoleIdGet(
      this.jobRoleId
    );

    this.model = response.data;
  }

  private async onJobRoleEdit() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.JobRoleService.apiJobroleJobRoleIdPut(
        this.jobRoleId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Job role saved");
      this.$emit("job-role-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to save job role");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
