































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import SnackbarModule from "@/store/snackbarModule";
import Validation from "@/mixins/validation";
import Api from "@/api";
import { JobRoleUpsertModel } from "@/api/generated";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class JobRoleCreateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: JobRoleUpsertModel = {
    name: "",
    discriminator: getContractType(this.discriminator)
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onJobRoleCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.JobRoleService.apiJobrolePost(this.model);
      snackbarModule.setSnackbarMessage("Job role created");
      this.$emit("job-role-created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create job role");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
