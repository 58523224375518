

































import { Vue, Component, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import nameof from "@/utility/nameof";
import SnackbarModule from "@/store/snackbarModule";
import { JobRoleModel } from "@/api/generated";
import Api from "@/api";
import getContractType from '../../utility/getContractType';
import Discriminator from '../../types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class JobRoleTable extends Vue {
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;
  @Prop({default: "Job Roles"}) TableTitle!: string;
  private loading = false;
  private jobRoles: Array<JobRoleModel> = [];
  private headers: Array<DataTableHeader<JobRoleModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<JobRoleModel>("name") },
    {
      text: "Created By",
      value: nameof<JobRoleModel>("createdByUserFullname")
    },
    { text: "Created", value: nameof<JobRoleModel>("createdUtc") }
  ];

  private async created() {
    await this.getJobRoles();
  }

  private async getJobRoles() {
    try {
      this.loading = true;
      const response = await Api.JobRoleService.apiJobroleDiscriminatorGet(getContractType(this.discriminator));
      this.jobRoles = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch job roles");
    } finally {
      this.loading = false;
    }
  }
}
